var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"mytable",attrs:{"headers":_vm.headers,"items":_vm.sellerOrders,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.id))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date_format")(item.date))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dollar_format")(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.$router.push('/market/order-detail/' + item.id)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }