<template>
  <div>
    <div class="container">
      <b-table striped hover :items="pendingList" :fields="fields">
        <template #cell(select)="data">
          <span class="custom-checkbox">
            <input v-model="selected" type="checkbox" :value="data.item.id" />
          </span>
        </template>
        <template #cell(id)="data">
          {{ data.item.id }}
        </template>
        <template #cell(seller)="data">
          {{ data.item.seller_company.business_profile.company_name }}
        </template>

        <template #cell(status)="data">
          {{ data.item.status }}
          <template v-if="data.item.reason != null && data.item.reason != ''">
            - {{ data.item.reason }}
          </template>
        </template>
        <template #cell(actions)="data">
          <v-btn
            class="ma-2"
            tile
            outlined
            color="success"
            @click="$router.push('/crm/applications/detail/' + data.item.id)"
          >
            View
          </v-btn>
        </template>
      </b-table>
      <div class="row">
        <b-pagination
          v-model="pendingCurrentPage"
          :total-rows="pendingListCount"
          :per-page="limit"
          style="margin: 0 auto;"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import { mapActions } from "vuex";
import PendingApplications from "@/graphql/queries/PendingApplications.gql";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },
  data() {
    return {
      selected: [],
      pendingCurrentPage: 1,
      pendingListCount: 0,
      limit: 5,
      pendingList: [],
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "id",
          label: "Document#",
        },
        {
          key: "seller",
          label: "Partner Name",
        },

        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  apollo: {
    pendingList: {
      query: PendingApplications,
      variables() {
        return {
          status: "pending",
          supplier_company: this.$store.state.auth.user.company_relation,
          limit: this.limit,
          start: (this.pendingCurrentPage - 1) * this.limit,
        };
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.getApplicationTotalCount({
        params:
          "status=pending&supplier_company=" +
          this.$store.state.auth.user.company_relation,
      }).then((res) => {
        this.pendingListCount = res.data;
      });

      this.$apollo.queries.pendingList.refetch();
    }, 1000);
  },
  methods: {
    ...mapActions([
      "getSupplierApplications",
      "getApplicationTotalCount",
      "updateApplication",
    ]),
    approve(id) {
      this.updateApplication({
        id: id,
        status: "approved",
      })
        .then((res) => {
          if (res.data != false) {
            Swal.fire({
              title: "",
              text: "The application has been successfully updated!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });

            location.reload();
          }
        })
        .catch(() => {
          this.$bvToast.toast("An Error Occured!", {
            title: "Error",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
          });
        });
    },
    async approveSelected() {
      if (this.selected.length > 0) {
        try {
          await this.selected.forEach((id) => {
            this.updateApplication({
              id: id,
              status: "approved",
            });
          });

          Swal.fire({
            title: "",
            text: "The application has been successfully updated!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });

          location.reload();
        } catch (err) {
          this.$bvToast.toast("An Error Occured!", {
            title: "Error",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
          });
        }
      }
    },
    denySelected() {
      if (this.selected.length > 0) {
        this.$bvModal
          .msgBoxConfirm("Are you sure to deny application?", {
            title: "Confirm",
            size: "sm",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.denyFunc();
            }
          });
      }
    },
    async denyFunc() {
      try {
        await this.selected.forEach((id) => {
          this.updateApplication({
            id: id,
            status: "denied",
          });
        });

        Swal.fire({
          title: "",
          text: "The application has been successfully updated!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        location.reload();
      } catch (err) {
        this.$bvToast.toast("An Error Occured!", {
          title: "Error",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
        });
      }
    },
  },
};
</script>
