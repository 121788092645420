<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        lazy-src="/media/biz-header.jpg"
        src="/media/header-home.jpg"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white">
                Welcome to Goose
              </div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;min-height:850px">
      <div class="container">
        <div v-if="$acl.check('isNotapproved')" class="row">
          <Registerpending></Registerpending>
        </div>

        <div
          v-if="
            $acl.not.check('isBuySell') &&
              $acl.not.check('isNotapproved') &&
              $acl.not.check('isAdmin')
          "
          :key="componentKey"
          class="row"
        >
          <Newuserapp></Newuserapp>
        </div>

        <div v-if="$acl.check('isSeller')">
          <div class="row justify-content-center" style="min-height:600px;">
            <div class="col-lg-6 col-sm-12 ">
              <full-bg-card> </full-bg-card>
            </div>

            <div class="col-lg-6 d-none d-md-block">
              THIS IS FOR ADMIN USE ONLY. PLEASE VISIT GOOSE.FARM for Buyer
              ACCESS
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-lg-12"></div>
            </div>
          </div>
        </div>

        <v-row v-if="$acl.check('isSupplier')">
          <v-col sm="6" md="6">
            <base-material-card
              icon="mdi-earth"
              title=" Welcome to the Goose Platform"
              class="h-100"
            >
              <v-row>
                <v-col cols="12" md="12" class="mt-10">
                  <p>
                    Thank you for joining the new tool to help companies work
                    better with their partners.
                  </p>

                  <p>
                    The site is currently being launched in Early Beta status.
                    This means that all of the features that will be in the
                    final platform may be functional but not look super pretty
                    yet. Additional functions like analytics, reporting, and
                    data views will be coming soon to help you get a 360 degree
                    view on your customers and sales
                  </p>
                </v-col>

                <v-col cols="12" md="6"> </v-col>
              </v-row>
            </base-material-card>
            <!--             
            <base-material-card
              icon="mdi-earth"
              title=" Sales by Top Partners -  November"
              class="h-100"
            >
              <v-row>
                <v-col cols="12" md="12" class="mt-10">
                  <v-simple-table class="ml-2">
                    <thead>
                      <tr>
                        <th class="text-left">
                          Partner
                        </th>
                        <th class="text-left">
                          Sales
                        </th>
                        <th class="text-left">
                          % to Goal
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(sale, i) in sales" :key="i">
                        <td v-text="sale.partner" />
                        <td v-text="'$ ' + sale.salesInM" />
                        <td
                          v-text="
                            ((sale.salesInM / totalSales) * 100).toFixed(2) +
                              '%'
                          "
                        />
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>

                <v-col cols="12" md="6"> </v-col>
              </v-row>
            </base-material-card> -->
          </v-col>

          <v-col v-if="$acl.check('isSupplier')" sm="6" md="6">
            <base-material-card
              color="secondary"
              icon="mdi-notebook"
              title="Pending Applications"
              class="h-100"
            >
              <Pendingpartners> </Pendingpartners>
            </base-material-card>
          </v-col>

          <v-col v-if="$acl.check('isSupplier')" cols="12" lg="12" class="mt-4">
            <base-material-card
              color="secondary"
              icon="mdi-cart"
              title="Recent Orders"
              class="h-100"
            >
              <SupplierRecentOrders> </SupplierRecentOrders>
            </base-material-card>
          </v-col>

          <!-- <v-col v-if="$acl.check('isSeller')" cols="12" lg="12" class="mt-4">
            <RecentOrders> </RecentOrders>
          </v-col> -->
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import SET_BREADCRUMB from "@/core/services/store/breadcrumbs.module";
import Registerpending from "../../view/pages/Registerpending.vue";
import Newuserapp from "../../components/Newuserapp.vue";
import Pendingpartners from "../../components/Pending-apps-widget.vue";
import SupplierRecentOrders from "../../components/SellerRecentOrdersWidget.vue";
import FullBgCard from "../../components/base/cards/FullBgCard.vue";
import Adcategory from "@/graphql/queries/Adcategory.gql";

export default {
  components: {
    Registerpending,
    FullBgCard,
    Newuserapp,
    Pendingpartners,
    SupplierRecentOrders,
  },

  apollo: {
    adcategory: {
      query: Adcategory,
      variables() {
        return {
          id: this.routeParam,
        };
      },
    },
  },

  data() {
    return {
      adcategory: {},
      routeParam: this.$route.params.id,

      componentKey: 0,
      api_url: process.env.VUE_APP_STRAPI_API_URL,

      countryData: {
        US: 2920,
      },
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },

      sales: [
        {
          partner: "Bobs discount",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/US.png",
          salesInM: 2920,
        },
        {
          partner: "Outdoor World",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/DE.png",
          salesInM: 1300,
        },
        {
          partner: "Fast Fashion",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/AU.png",
          salesInM: 760,
        },
        {
          partner: "Acme Local",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/GB.png",
          salesInM: 690,
        },
        {
          partner: "Pop Up Store",
          flag:
            "https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/RO.png",
          salesInM: 600,
        },
      ],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },

  computed: {
    totalSales() {
      return this.sales.reduce((acc, val) => acc + val.salesInM, 0);
    },
    features5() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
  mounted() {
    setTimeout(() => {
      this.componentKey += 1;
      this.$store.dispatch(SET_BREADCRUMB, [{ path: "/dashboard" }]);
    }, 500);
  },

  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
  },
};
</script>
