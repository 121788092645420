<style>


    .jumbotron {

        background-image: url("/media/bg/green-small-header.jpg")

    }

</style>


<template>
<div>
<div class="jumbotron" style="text-align: center;">
          <h2 class="h2-responsive text-white">The Goose Product Discover & Channel Platform</h2>
          <br>
          <p class="text-white">Powerful and easy Tools for your Brand</p>
          <hr>
          <p class="text-white">Register your retail store to find new brands, simplify ordering, and easy access to product data.</p>

        </div>

 <div class="card-deck row">
    <div v-if="currentUser.invited_supplier == '' || currentUser.invited_supplier == null" class="col-6">
        <div class="card mb-2">
            <img class="card-img-top img-fluid" src="/media/bg/brand-app.jpg" alt="Retail header">
            <div class="card-body">
                <h2 class="card-title">Brands</h2>
                            <b-button href="/applications/companyapp" variant="outline-dark">Complete Signup</b-button>

                <p class="card-text pt-5">Start your profile now and better connect with your partners.</p>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card mb-2">
            <img class="card-img-top img-fluid" src="/media/bg/retailer-app.jpg" alt="Retail header">
            <div class="card-body">
                <h2 class="card-title">Retailers</h2>
                            <b-button href="/applications/retailerapp" variant="outline-dark">Complete Signup</b-button>

                <p class="card-text pt-5">Find new suppliers and products with our Quick Apply with your Buyer Profile</p>
            </div>
        </div>
    </div>
  </div>

  <!--Main layout-->
  <div class="container">


    <hr class="extra-margins">


  </div>
  <!--/.Main layout-->
















    </div>




</template>


<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters([
			'currentUser'
		]),
	}
}
</script>