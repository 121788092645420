<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sellerOrders"
      class="mytable"
      hide-default-footer
      disable-pagination
    >
      <template #[`item.id`]="{ item }">
        <div class="subtitle-2">{{ item.id }}</div>
      </template>
      <template #[`item.date`]="{ item }">
        {{ item.date | date_format }}
      </template>
      <template #[`item.total`]="{ item }">
        {{ item.total | dollar_format }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon @click="$router.push('/market/order-detail/' + item.id)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import GetOrdersHome from "@/graphql/queries/GetOrdersHome.gql";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    }
  },
  data: () => ({
    dialog: false,
    headers: [
      { text: "Order ID", value: "id" },
      { text: "Date", value: "date" },
      { text: "Business Name", value: "buyerCompany" },
      { text: "User", value: "buyerUser" },
      { text: "Total $", value: "total" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions" }
    ]
  }),
  apollo: {
    orders: {
      query: GetOrdersHome,
      variables() {
        return { vendorCompany: this.$store.state.auth.user.company_relation };
      },
      skip() {
        return !this.$store.state.auth.user; // this is true so the query wont execute on load
      }
    }
  },
  computed: {
    sellerOrders() {
      if (this.orders !== null && this.orders !== undefined) {
        const order = this.orders.map(sellerOrder => {
          const date = sellerOrder.created_at;
          const buyerUser = sellerOrder.masterOrder.buyerUser.FirstName.concat(
            " ",
            sellerOrder.masterOrder.buyerUser.LastName
          );
          const buyerCompany =
            sellerOrder.masterOrder.buyerCompany.company_name;
          const id = sellerOrder.identifier;
          const total = sellerOrder.total;
          const subtotal = sellerOrder.subtotal;
          const shiptotal = sellerOrder.shiptotal;

          const status = this.currentStatus(sellerOrder.historicalStatus);
          return {
            id,
            total,
            date,
            subtotal,
            shiptotal,
            buyerUser,
            buyerCompany,
            status
          };
        });
        return order;
      } else {
        return [];
      }
    }
  },

  methods: {
    currentStatus(historicalStatus) {
      var sorted = historicalStatus.map(status => ({ ...status }));
      sorted.sort((a, b) => {
        new Date(a.date) - new Date(b.date);
      });
      return sorted.length > 0 ? sorted[sorted.length - 1].status : "";
    }
  }
};
</script>
